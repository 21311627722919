// PropertyDetail.js
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick"; // Import react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SimbaLogo from "../../../Assests/SimbaLogo.png";
import {
  projecRelated,
  projectDetailById,
} from "../../../action/projectAction";
import { BASE_URL } from "../../../env";

const ProjectDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.ProjectDetail);
  const { loading, error, projectbyID } = projects;

  const relatedprojects = useSelector((state) => state.relatedProjects);
  const {
    loading: loadingRelated,
    error: errorrelated,
    relateProject,
  } = relatedprojects;

  const filterRelatedData = relateProject?.filter(
    (item) => item.id !== undefined
  );

  useEffect(() => {
    dispatch(projectDetailById(id));
    dispatch(projecRelated(id));
  }, [dispatch, id]);

  // Slick slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
  };

  return (
    <>
      <nav
        className="flex py-5 px-5 sm:px-10 md:px-32 lg:px-32 text-white font-bold bg-white  mx-auto max-w-screen-2xl   overflow-x-hidden"
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li className="inline-flex items-center">
            <a
              href="#"
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              Home
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <a
                href="#"
                className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
              >
                News
              </a>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                {projectbyID?.title}
              </span>
            </div>
          </li>
        </ol>
      </nav>

      <div
        className="flex flex-col lg:flex-row px-5 sm:px-5 md:px-10   text-black lg:px-8 mx-auto max-w-screen-2xl bg-white 
        overflow-x-hidden"
      >
        <div className="lg:w-8/12 lg:pr-14 lg:mb-0 relative mt-5">
          <div className="flex flex-wrap md:flex-nowrap items-center mb-5">
            <div className="border rounded-xl inline-block mb-4 md:mb-0 md:mr-6 lg:mr-4 xl:mr-6">
              <div className="p-2">
                <img
                  src={SimbaLogo}
                  alt="..."
                  className=""
                  width={68}
                  height={68}
                />
              </div>
            </div>
            <div className="mb-4 md:mb-0 md:mr-8 lg:mr-4 xl:mr-8">
              <h6 className="mb-0">Developed by</h6>
              <a href="#" className="text-lg font-bold text-purple-500 ">
                SimbaTech Tecnology Solutions
              </a>
            </div>
          </div>

          <a
            href="#"
            className="relative block rounded-lg overflow-hidden mb-6"
            data-fancybox
          >
            <div className="absolute flex items-center justify-center bg-white bg-opacity-50"></div>

            <Slider {...sliderSettings}>
              <img
                src={projectbyID?.image}
                className="rounded-lg shadow-lg w-full h-[500px]"
                alt="Project Image"
              />
              <img
                src={projectbyID?.image1}
                className="rounded-lg shadow-lg w-full h-[500px]"
                alt="Project Image"
              />
              <img
                src={projectbyID?.image2}
                className="rounded-lg shadow-lg w-full h-[500px]"
                alt="Project Image"
              />
              <img
                src={projectbyID?.image3}
                className="rounded-lg shadow-lg w-full h-[500px]"
                alt="Project Image"
              />
              <img
                src={projectbyID?.image4}
                className="rounded-lg shadow-lg w-full h-[500px]"
                alt="Project Image"
              />
            </Slider>
            {/* {projectbyID?.image ? (
              <img
                src={projectbyID?.image}
                alt="News Images"
                className="rounded-lg shadow-lg w-full h-[500px]"
              />
            ) : (
              <img
                src="/assets/img/laptop-news.png"
                alt="Default News Image"
                className="rounded-lg shadow-lg w-full h-[500px]"
              />
            )} */}
          </a>

          <div className="">
            {/* <span className="bg-yellow-500 text-white font-semibold px-4 py-2 text-sm rounded-full inline-block">
              NEWS
            </span> */}
            <p>
              {/* <span className="font-semibold">Post Date:</span>{" "} */}
              {/* {extractDate(projectbyID?.created_at)} */}
            </p>
            <h1 className="text-gray-900 font-semibold text-3xl mt-3 text-left">
              {projectbyID?.title}
            </h1>
            <p className="text-gray-700 mt-2">{projectbyID?.description}</p>
            <p className="text-gray-700 mt-2">
              {/* Organization: {projectbyID?.name_org} */}
            </p>
          </div>
        </div>
        {/*Side menu price and details  */}
        <div className="lg:w-6/12 bg-white">
          <div className="p-2 shadow mb-6">
            <h1 className="text-2xl font-bold text-gray-800 text-center mb-4">
              Related Projects
            </h1>
            {relateProject?.map((relatedproject, index) => (
              <div
                key={index}
                className="flex flex-col mb-6 md:flex-row md:items-center md:mb-0"
              >
                <a href={`/project_details/${relatedproject.id}`}>
                  <img
                    src={`${BASE_URL}${relatedproject.image}`}
                    alt="News Image"
                    className="w-48 h-48  object-cover rounded-lg shadow-md mb-2 md:w-48 md:h-36 md:mr-4"
                    // onMouseEnter={() => setHoveredImage(news[0].id)}
                    // onMouseLeave={() => setHoveredImage(null)}
                  />
                </a>
                <div className="flex-1">
                  <h2 className="text-lg font-semibold text-gray-900 mb-2">
                    <a href={`/project_details/${relatedproject.id}`}>
                      {relatedproject.title}
                    </a>
                  </h2>
                  <p className="text-gray-700 mb-2">
                    <p className="text-gray-900  text-md mt-3 text-left">
                      {relatedproject.description.substring(
                        0,
                        relatedproject.description.length / 4
                      )}
                      <p className=" text-gray-700 mb-2 md:hidden lg:hidden text-left">
                        {relatedproject.description.substring(
                          relatedproject.description.length / 4
                        )}
                      </p>
                    </p>
                  </p>
                  <p>
                    <span className="font-semibold">Post date:</span>{" "}
                    {/* {extractDate(relatedproject.created_at)} */}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
    // <div className="container mx-auto flex">
    //   <div className="w-3/4 py-8">
    //     <h1 className="text-2xl font-bold mb-4">{projectbyID?.title}</h1>
    //     {/* Image Slider */}
    //     <Slider {...sliderSettings}>
    //       <img
    //         src={projectbyID?.image}
    //         className="w-full h-auto"
    //         alt="Project Image"
    //       />
    //       <img
    //         src={projectbyID?.image1}
    //         className="w-full h-auto"
    //         alt="Project Image"
    //       />
    //       <img
    //         src={projectbyID?.image2}
    //         className="w-full h-auto"
    //         alt="Project Image"
    //       />
    //       <img
    //         src={projectbyID?.image3}
    //         className="w-full h-auto"
    //         alt="Project Image"
    //       />
    //       <img
    //         src={projectbyID?.image4}
    //         className="w-full h-auto"
    //         alt="Project Image"
    //       />
    //     </Slider>
    //     <p className="my-4">{projectbyID?.description}</p>
    //     {/* Add more details about the project */}
    //   </div>
    //   <div className="w-1/4 py-8 pl-4">
    //     <h2 className="text-xl font-bold mb-4">Similar Projects</h2>
    //     {/* List of similar projects */}
    //     <ul className="list-disc">
    //       <li>Project A</li>
    //       <li>Project B</li>
    //       <li>Project C</li>
    //       {/* Add more similar projects */}
    //     </ul>
    //   </div>
    // </div>
  );
};

export default ProjectDetail;
