import { Link } from "react-router-dom";
import { projects } from "../../../data/dummyData";
import { useDispatch, useSelector } from "react-redux";
import { projectList } from "../../../action/projectAction";
import { useEffect } from "react";

const Projects = () => {
  // const { currentDataItems } = useSelector(dataStore);
  const dispatch = useDispatch();
  const { loading, error, projects } = useSelector(
    (state) => state.ProjectList
  );


  useEffect(() => {
    dispatch(projectList());
  }, [dispatch]);
  return (
    <div className="pt-10 pb-16">
      <div className="text-center">
        {/* <h1 className="mx-auto sub-heading"></h1> */}
        <h2 className=" section-title mx-auto text-4xl font-extrabold  text-primary">
          our projects
        </h2>
        <h1 className="heading">excellent projects both small and complex</h1>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-8 sm:grid-cols-2 md:grid-cols-3">
        {projects?.map((item) => (
          <div key={item.id} className="relative group">
            <div className="overflow-hidden rounded-lg shadow-lg">
              <Link to={`/project_details/${item.id}`} className="block">
                <img
                  src={item.image}
                  alt={item.title}
                  className="w-full h-[250px] object-cover group-hover:scale-105 transition-transform duration-300"
                />
              </Link>
            </div>
            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/80 to-transparent px-4 py-2">
              <h1 className="text-lg font-semibold text-white">{item.title}</h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
