import axios from "axios";
import {} from "../constants/CourseConstants";
import {
  NEWS_CREATE_FAIL,
  NEWS_CREATE_REQUEST,
  NEWS_CREATE_SUCCESS,
  NEWS_DETAILS_FAIL,
  NEWS_DETAILS_REQUEST,
  NEWS_DETAILS_SUCCESS,
  NEWS_LIST_FAIL,
  NEWS_LIST_REQUEST,
  NEWS_LIST_SUCCESS,
  NEWS_RELATED_FAIL,
  NEWS_RELATED_REQUEST,
  NEWS_RELATED_SUCCESS,
} from "../constants/newsConstants";
import { BASE_URL } from "../env";

// BASE_IP = "http://127.0.0.1:8000";

export const newsList = () => async (dispatch) => {
  dispatch({
    type: NEWS_LIST_REQUEST,
  });
  try {
    const { data } = await axios.get(`${BASE_URL}/news/`);

    dispatch({
      type: NEWS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEWS_LIST_FAIL,
      payload: error,
    });
  }
};

export const eventDetailById = (id) => async (dispatch) => {
  dispatch({
    type: NEWS_DETAILS_REQUEST,
    payload: id,
  });
  try {
    const { data } = await axios.get(`${BASE_URL}/news/${id}`);

    dispatch({
      type: NEWS_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEWS_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const createNews = (news) => async (dispatch) => {
  dispatch({ type: NEWS_CREATE_REQUEST });
  try {
    const { data } = await axios.post(`${BASE_URL}/news/${news}`);
    dispatch({
      type: NEWS_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEWS_CREATE_FAIL,
      payload: error,
    });
  }
};

export const newsRelated = (id) => async (dispatch) => {
  dispatch({
    type: NEWS_RELATED_REQUEST,
    payload: id,
  });
  try {
    const { data } = await axios.get(`${BASE_URL}/relatednews/${id}`);

    dispatch({
      type: NEWS_RELATED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEWS_RELATED_FAIL,
      payload: error,
    });
  }
};
