import { Helmet } from "react-helmet";
import {
  Categories,
  Counter,
  Feeds,
  Services as ServicesList,
  Team,
} from "../components/common/page-componets";

const Services = () => {
  return (
    <>
      <Helmet>
        <meta
          charSet="utf-8"
          name="SimbaTech pages"
          content="simbatech services page"
        />
        <title>SimbaTech | Services</title>
        <link rel="canonical" href="/services" />
      </Helmet>

      <div className="pt-20 px-[3%] md:px-[6%]">
        <ServicesList />
        {/* <Categories />
      <Counter />
      <Team />
      <Feeds /> */}
      </div>
    </>
  );
};

export default Services;
