import axios from "axios";
import {
  TESTIMONIAL_LIST_FAIL,
  TESTIMONIAL_LIST_REQUEST,
  TESTIMONIAL_LIST_SUCCESS,
} from "../constants/testimonialConstants";
import {
  PROJECT_DETAILS_FAIL,
  PROJECT_DETAILS_REQUEST,
  PROJECT_DETAILS_SUCCESS,
} from "../constants/projectConstants";
import { BASE_URL } from "../env";

export const testimonialList = () => async (dispatch) => {
  dispatch({
    type: TESTIMONIAL_LIST_REQUEST,
  });
  try {
    const { data } = await axios.get(`${BASE_URL}/testimonial/`);

    dispatch({
      type: TESTIMONIAL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TESTIMONIAL_LIST_FAIL,
      payload: error,
    });
  }
};
export const testimonialDetailById = (id) => async (dispatch) => {
  dispatch({ type: PROJECT_DETAILS_REQUEST });
  try {
    const { data } = await axios.get(`${BASE_URL}/project/${id}`);
    dispatch({
      type: PROJECT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROJECT_DETAILS_FAIL,
      payload: error,
    });
  }
};
