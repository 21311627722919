import { Helmet } from "react-helmet";
import {
  Brands,
  Counter,
  Featured,
  Projects,
  Services,
  Testimonial,
} from "../components/common/page-componets";
import {
  Feeds,
  Filters,
  Hero,
  Invest,
  Speciality,
} from "../components/home/home-1";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta
          charSet="utf-8"
          name="SimbaTech | Home"
          content="simbatech home page"
        />
        <title>SimbaTech | Home</title>
        <link rel="canonical" href="/" />
      </Helmet>

      <div className="pt-16 px-[3%] md:px-[6%]">
        <Hero />
        {/* <Filters /> */}
        {/* <Invest /> */}
        <Speciality />
        <Services />
        {/* <Featured /> */}
        <Counter />
        <Projects />
        <Testimonial />
        <Brands />
        <Feeds />
      </div>
    </>
  );
};

export default Home;
