import { BiBuildingHouse } from "react-icons/bi";
import {
  FaInstagram,
  FaLinkedin,
  FaTelegram,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { Link } from "react-router-dom";
import SimbaLogo from "../../Assests/SimbaLogo.png";

const Footer = () => {
  return (
    <div className="text-white">
      <footer className="flex flex-wrap gap-5">
        <div className="flex-1 basis-[10rem]">
          <Link to="/" className="flex items-center gap-x-1">
            <img src={SimbaLogo} alt="simbatech" className="h-24 w-24" />
            <h1 className="hidden md:block text-xl">
              SimbaTech Technology PLC
            </h1>
          </Link>
          <div className="mt-3">
            <p className="text-sm">
              Our cutting-edge products harness the latest in technological
              innovation to provide you with unparalleled security.
            </p>
            <div className="gap-5 my-6 flex-center-center">
              <a
                href="#"
                className="icon-box bg-dark-light hover:bg-hover-color-dark"
              >
                <FiFacebook />
              </a>
              <a
                href="#"
                className="icon-box bg-dark-light hover:bg-hover-color-dark"
              >
                <FaYoutube />
              </a>
              <a
                href="#"
                className="icon-box bg-dark-light hover:bg-hover-color-dark"
              >
                <FaInstagram />
              </a>
              <a
                href="#"
                className="icon-box bg-dark-light hover:bg-hover-color-dark"
              >
                <FaLinkedin />
              </a>
              <a
                href="#"
                className="icon-box bg-dark-light hover:bg-hover-color-dark"
              >
                <FaTiktok />
              </a>
              <a
                href="#"
                className="icon-box bg-dark-light hover:bg-hover-color-dark"
              >
                <FaTelegram />
              </a>
            </div>
          </div>
        </div>
        <div className="flex-1 basis-[10rem]">
          <h2 className="text-xl font-semibold">Services</h2>
          <ul>
            <li className="my-3 text-muted">
              <a href="#">Software Development</a>
            </li>
            <li className="my-3 text-muted">
              <a href="#">Training</a>
            </li>
            <li className="my-3 text-muted">
              <a href="#">Terms of Use</a>
            </li>
            <li className="my-3 text-muted">
              <a href="#">Contact Support</a>
            </li>
          </ul>
        </div>
        <div className="flex-1 basis-[10rem]">
          <h2 className="text-xl font-semibold">Quick Links</h2>
          <ul>
            <li className="my-3 text-muted">
              <a href="/about-us">About Us</a>
            </li>
            <li className="my-3 text-muted">
              <a href="/services">Services</a>
            </li>
            <li className="my-3 text-muted">
              <a href="/events">Events</a>
            </li>
            <li className="my-3 text-muted">
              <a href="/project">Portfolio</a>
            </li>
          </ul>
        </div>
      </footer>
      <div className="py-2 mt-3 text-center border-t text-muted border-dark">
        <p>
          Created By{" "}
          <span className="text-primary">
            SimbaTech Technology Solution PLC
          </span>{" "}
          | All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
