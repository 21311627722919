// PropertyDetail.js
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick"; // Import react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SimbaLogo from "../Assests/SimbaLogo.png";
import { eventDetailById, newsRelated } from "../action/newsAction";
import { BASE_URL } from "../env";

const EventsDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const newses = useSelector((state) => state.eventDetail);
  const { loading, error, news } = newses;

  const relatedNews = useSelector((state) => state.relatedNews);

  const {
    loading: loadingRelated,
    error: errorrelated,
    Relatednews,
  } = relatedNews;

  useEffect(() => {
    dispatch(eventDetailById(id));
    dispatch(newsRelated(id));
  }, [dispatch, id]);

  // Slick slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <>
      <nav
        className="flex py-5 px-5 sm:px-10 md:px-32 text-white font-bold bg-white lg:px-8 mx-auto max-w-screen-2xl   overflow-x-hidden"
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li className="inline-flex items-center">
            <a
              href="#"
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              Home
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <a
                href="#"
                className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
              >
                Events
              </a>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                {news?.title}
              </span>
            </div>
          </li>
        </ol>
      </nav>

      <div
        className="flex flex-col lg:flex-row px-5 sm:px-5 md:px-10   text-black lg:px-8 mx-auto max-w-screen-2xl bg-white 
        overflow-x-hidden"
      >
        <div className="lg:w-8/12 lg:pr-14 lg:mb-0 relative mt-5">
          <div className="flex flex-wrap md:flex-nowrap items-center mb-5">
            <div className="border rounded-xl inline-block mb-4 md:mb-0 md:mr-6 lg:mr-4 xl:mr-6">
              <div className="p-2">
                <img
                  src={SimbaLogo}
                  alt="..."
                  className=""
                  width={68}
                  height={68}
                />
              </div>
            </div>
            <div className="mb-4 md:mb-0 md:mr-8 lg:mr-4 xl:mr-8">
              <h6 className="mb-0">Organized by</h6>
              <a href="#" className="text-lg font-bold text-purple-500 ">
                SimbaTech Tecnology Solutions
              </a>
            </div>
          </div>

          <a
            href="#"
            className="relative block rounded-lg overflow-hidden mb-6"
            data-fancybox
          >
            <div className="absolute flex items-center justify-center bg-white bg-opacity-50"></div>

            <Slider {...sliderSettings}>
              <img
                src={news?.image}
                className="rounded-lg shadow-lg w-full h-[500px]"
                alt="Project Image"
              />
              <img
                src={news?.image1}
                className="rounded-lg shadow-lg w-full h-[500px]"
                alt="Project Image"
              />
              <img
                src={news?.image2}
                className="rounded-lg shadow-lg w-full h-[500px]"
                alt="Project Image"
              />
              <img
                src={news?.image3}
                className="rounded-lg shadow-lg w-full h-[500px]"
                alt="Project Image"
              />
              <img
                src={news?.image4}
                className="rounded-lg shadow-lg w-full h-[500px]"
                alt="Project Image"
              />
            </Slider>
            {/* {news?.image ? (
              <img
                src={news?.image}
                alt="News Images"
                className="rounded-lg shadow-lg w-full h-[500px]"
              />
            ) : (
              <img
                src="/assets/img/laptop-news.png"
                alt="Default News Image"
                className="rounded-lg shadow-lg w-full h-[500px]"
              />
            )} */}
          </a>

          <div className="">
            {/* <span className="bg-yellow-500 text-white font-semibold px-4 py-2 text-sm rounded-full inline-block">
              NEWS
            </span> */}
            <p>
              {/* <span className="font-semibold">Post Date:</span>{" "} */}
              {/* {extractDate(news?.created_at)} */}
            </p>
            <h1 className="text-gray-900 font-semibold text-3xl mt-3 text-left">
              {news?.title}
            </h1>
            <p className="text-gray-700 mt-2">{news?.description}</p>
            <p className="text-gray-700 mt-2">Organization: {news?.name_org}</p>
          </div>
        </div>
        {/*Side menu price and details  */}
        <div className="lg:w-6/12 bg-white">
          <div className="p-2 shadow mb-6">
            <h1 className="text-2xl font-bold text-gray-800 text-center mb-4">
              Related Events
            </h1>
            {Relatednews?.map((newsrelated, index) => (
              <div
                key={index}
                className="flex flex-col mb-6 md:flex-row md:items-center md:mb-0"
              >
                <a href={`/event_details/${newsrelated.id}`}>
                  <img
                    src={`${BASE_URL}${newsrelated.image}`}
                    alt="News Image"
                    className="w-48 h-48 object-cover rounded-lg shadow-md mb-2 md:w-48 md:h-36 md:mr-4"
                    // onMouseEnter={() => setHoveredImage(news[0].id)}
                    // onMouseLeave={() => setHoveredImage(null)}
                  />
                </a>
                <div className="flex-1">
                  <h2 className="text-lg font-semibold text-gray-900 mb-2">
                    <a href={`/news-details-Page/${newsrelated.id}`}>
                      {newsrelated.title}
                    </a>
                  </h2>
                  <p className="text-gray-700 mb-2">
                    {newsrelated.description.substring(
                      0,
                      newsrelated.description.length / 4
                    )}
                    <span className="text-gray-700 mb-2 md:hidden lg:hidden">
                      {newsrelated.description.substring(
                        newsrelated.description.length / 4
                      )}
                    </span>
                  </p>
                  <p>
                    {/* <span className="font-semibold">Post date:</span>{" "} */}
                    {/* {extractDate(newsrelated.created_at)} */}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsDetail;
