import { dataStore } from "../../../features/dataSlice";
import SingleProductCard from "../../common/page-componets/SingleProductCard";

import { feeds } from "../../../data/dummyData";
import { useEffect } from "react";
import { createNews, newsList } from "../../../action/newsAction";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { projectList } from "../../../action/projectAction";
const PropertyList = ({ basis }) => {
  // const { currentDataItems } = useSelector(dataStore);

  const { currentDataItems } = useSelector(dataStore);
  // const { currentDataItems } = useSelector(dataStore);
  const dispatch = useDispatch();
  const { loading, error, projects } = useSelector(
    (state) => state.ProjectList
  );

  useEffect(() => {
    dispatch(projectList());
  }, [dispatch]);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {projects?.map((item) => (
        <div
          key={item.id}
          className={`bg-white shadow-light dark:bg-gray-800 dark:border-card-dark border rounded-lg overflow-hidden relative group`}
        >
          <div className="group overflow-hidden relative">
            <Link to={`/project_details/${item.id}`} className="block">
              <img
                src={item?.image}
                alt={item.title}
                className="w-full h-[250px] object-cover group-hover:scale-105 transition-transform duration-300"
              />
            </Link>
          </div>
          <div className="p-3">
            <Link
              to="/"
              className="group-hover:text-primary transition-a block mb-2 text-lg font-bold capitalize"
            >
              {item.title}
            </Link>
            <p className="text-sm mb-4">
              {item.description.length > 100
                ? `${item.description.slice(0, 100)}...`
                : item.description}
            </p>
            <div className="flex justify-between items-center">
              <a href={`/project_details/${item.id}`}>
                <button className="btn btn-secondary">Details</button>
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PropertyList;
