import { Helmet } from "react-helmet";
import { Pagination } from "../components/common/page-componets";
import { PropertyList } from "../components/property";
import { property } from "../data/dummyData";

const Property = () => {
  return (
    <>
      <Helmet>
        <meta
          charSet="utf-8"
          name="SimbaTech pages"
          content="simbatech Projects page"
        />
        <title>SimbaTech | Projects</title>
        <link rel="canonical" href="/project" />
      </Helmet>
      <div className="pt-16 px-[3%] md:px-[6%]">
        <PropertyList basis="basis-[25rem]" />
        <Pagination itemsPerPage={6} pageData={property} />
      </div>
    </>
  );
};

export default Property;
