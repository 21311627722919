// import { feeds } from "../../../data/dummyData";
// import SingleFeedCardGrid from "../../common/page-componets/SingleFeedCardGrid";

// const Feeds = () => {
//   return (
//     <div className="pt-10 pb-16">
//       <div className="text-center">
//         {/* <h1 className="mx-auto sub-heading">blog post</h1> */}
//         <h2 className=" section-title mx-auto text-4xl font-extrabold  text-primary">
//           Latest News{" "}
//         </h2>
//         {/* <h1 className="heading">latest newsfeeds</h1> */}
//       </div>
//       <div className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2">
//         {feeds.slice(0, 4).map((feed) => (
//           <SingleFeedCardGrid key={feed.id} {...feed} />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Feeds;

import { feeds } from "../../../data/dummyData";
import SingleFeedCardGrid from "../../common/page-componets/SingleFeedCardGrid";
import { useEffect } from "react";
import { createNews, newsList } from "../../../action/newsAction";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

const Feeds = () => {
  // const { currentDataItems } = useSelector(dataStore);
  const dispatch = useDispatch();
  const { loading, error, news } = useSelector((state) => state.NewsList);

  useEffect(() => {
    dispatch(newsList());
  }, [dispatch]);

  return (
    <div className="pt-10 pb-16">
      <div className="text-center">
        {/* <h1 className="mx-auto sub-heading">blog post</h1> */}
        {/* <h1 className="heading">latest newsfeeds</h1> */}
        <h1
          className="mx-auto heading"
          style={{
            color: "orange",
            textAlign: "center",
            fontSize: "2em",
            margin: "20px 0",
          }}
        >
          Blog Post
        </h1>
        <h1 className="heading">latest newsfeeds</h1>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-8 md:grid-cols-2">
        {news?.map((item) => (
          <div className="flex flex-col gap-3 sm:flex-row group">
            <div className="relative flex-shrink-0">
              <img
                src={item.image}
                alt={item.title}
                className="object-cover w-full h-64 rounded-lg sm:w-48"
              />
              <div className="absolute right-0 -rotate-90 translate-y-1/2 top-1/2">
                <span className="px-3 py-1 text-white capitalize rounded-full bg-secondary">
                  {/* {category} */}
                </span>
              </div>
            </div>
            <div>
              <Link
                to={`/event_details/${item.id}`}
                className="group-hover:text-primary transition-a"
              >
                <h1 className="text-lg font-semibold capitalize">
                  {item.title}
                </h1>
              </Link>
              <p className="mt-3 text-sm uppercase opacity-60">
                {/* by {author?.name}: {date_posted} */}
              </p>
              <p className="mt-2">{item.description.slice(0, 200)}...</p>
              <Link
                to={`/event_details/${item.id}`}
                className="mt-4 flex-align-center gap-x-2 hover:underline text-primary"
              >
                <span className="uppercase hover:underline">read more</span>{" "}
                <FiArrowRight />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Feeds;
