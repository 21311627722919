import { Helmet } from "react-helmet";
import { ContactInfo, Form, Map } from "../components/contact";

const Contact = () => {
  return (
    <>
      <Helmet>
        <meta
          charSet="utf-8"
          name="SimbaTech pages"
          content="simbatech Contacts page"
        />
        <title>SimbaTech | Contacts</title>
        <link rel="canonical" href="/contact" />
      </Helmet>
      <div className="pt-20 px-[3%] md:px-[6%]">
        <Map />
        <ContactInfo />
        {/* <Map /> */}
        <Form />
      </div>
    </>
  );
};

export default Contact;
