import { BiMap } from "react-icons/bi";
import { FiMail, FiPhone } from "react-icons/fi";

const ContactInfo = () => {
  return (
    <div className="grid grid-cols-1 gap-6 py-16 sm:grid-cols-2 md:grid-cols-3">
      <div className="text-center">
        <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
          <FiPhone />
        </div>
        <h1 className="mt-2 text-lg font-semibold">Phone Number</h1>
        <a href="">
          <p className="text-xl">+251 92 233 3908</p>
        </a>
        <p className="text-xl">+251 91 028 5276</p>
        <p className="text-xl">+251 91 434 3893</p>
      </div>
      <div className="text-center">
        <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
          <FiMail />
        </div>
        <h1 className="mt-2 text-lg font-semibold">Email Address</h1>
        <p>info@simbatech.et</p>
        {/* <p>wabwenib66@gmail.com</p> */}
      </div>
      <div className="text-center">
        <div className="icon-box !h-14 !w-14 !bg-primary text-white mx-auto text-2xl">
          <BiMap />
        </div>
        <h1 className="mt-2 text-lg font-semibold">Office Address</h1>
        <p>Addis Ababa, mexico lideta merekato 6th floor</p>
        {/* <p>Horizon Street, Middle Avenue, USA</p> */}
      </div>
    </div>
  );
};

export default ContactInfo;
