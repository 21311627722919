import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { useDispatch } from "react-redux";

const Map = () => {
  const mapContainerStyle = {
    width: "100%",
    height: "600px",
  };

  const center = {
    lat: 9.031354951126465,
    lng: 38.749997562553745,
  };

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const mapRef = useRef(null); // Reference to the map component

  const onSelectMarker = (marker) => {
    setSelectedMarker(marker);
  };

  const onCloseInfoWindow = () => {
    setSelectedMarker(null);
  };

  // const api = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // Load from environment variable
  const api = "AIzaSyB8cS0kZ25Id4FJi7aGC587nCnTKwg7Dio";

  const dispatch = useDispatch();

  useEffect(() => {
    setMapLoaded(true); // Set mapLoaded to true when the component mounts
  }, []);

  return (
    <LoadScript
      googleMapsApiKey={api}
      onError={() => alert("Error loading Google Maps")}
    >
      {mapLoaded && ( // Render map only when mapLoaded is true
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={center}
          zoom={15}
          onLoad={(map) => (mapRef.current = map)} // Save reference to map
        >
          <Marker
            position={center}
            onClick={() => onSelectMarker(center)}
            icon={{
              url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
            }}
          />

          {selectedMarker && (
            <InfoWindow
              position={selectedMarker.position}
              onCloseClick={onCloseInfoWindow}
            >
              <div>
                <h3>Marker Information</h3>
                <p>Latitude: {selectedMarker.position.lat}</p>
                <p>Longitude: {selectedMarker.position.lng}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </LoadScript>
  );
};

export default Map;
