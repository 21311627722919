export const NEWS_LIST_REQUEST = "NEWS_LIST_REQUEST";
export const NEWS_LIST_SUCCESS = "NEWS_LIST_SUCCESS";
export const NEWS_LIST_FAIL = "NEWS_LIST_FAIL";

export const NEWS_DETAILS_REQUEST = "NEWS_DETAILS_REQUEST";
export const NEWS_DETAILS_SUCCESS = "NEWS_DETAILS_SUCCESS";
export const NEWS_DETAILS_FAIL = "NEWS_DETAILS_FAIL";

export const NEWS_RELATED_REQUEST = "NEWS_RELATED_REQUEST";
export const NEWS_RELATED_SUCCESS = "NEWS_RELATED_SUCCESS";
export const NEWS_RELATED_FAIL = "NEWS_RELATED_FAIL";

export const NEWS_CREATE_REQUEST = "NEWS_CREATE_REQUEST";
export const NEWS_CREATE_SUCCESS = "NEWS_CREATE_SUCCESS";
export const NEWS_CREATE_FAIL = "NEWS_CREATE_FAIL";
export const NEWS_CREATE_RESET = "NEWS_CREATE_RESET";

export const NEWS_UPDATE_REQUEST = "NEWS_UPDATE_REQUEST";
export const NEWS_UPDATE_SUCCESS = "NEWS_UPDATE_SUCCESS";
export const NEWS_UPDATE_FAIL = "NEWS_UPDATE_FAIL";
export const NEWS_UPDATE_RESET = "NEWS_UPDATE_RESET";

export const NEWS_DELETE_REQUEST = "NEWS_DELETE_REQUEST";
export const NEWS_DELETE_SUCCESS = "NEWS_DELETE_SUCCESS";
export const NEWS_DELETE_FAIL = "NEWS_DELETE_FAIL";
export const NEWS_DELETE_RESET = "NEWS_DELETE_RESET";
