import axios from "axios";
import {
  PROJECT_DETAILS_FAIL,
  PROJECT_DETAILS_REQUEST,
  PROJECT_DETAILS_SUCCESS,
  PROJECT_LIST_FAIL,
  PROJECT_LIST_REQUEST,
  PROJECT_LIST_SUCCESS,
  PROJECT_RELATED_FAIL,
  PROJECT_RELATED_REQUEST,
  PROJECT_RELATED_SUCCESS,
} from "../constants/projectConstants";
import { BASE_URL } from "../env";

export const projectList = () => async (dispatch) => {
  dispatch({
    type: PROJECT_LIST_REQUEST,
  });
  try {
    const { data } = await axios.get(`${BASE_URL}/project/`);

    dispatch({
      type: PROJECT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROJECT_LIST_FAIL,
      payload: error,
    });
  }
};

export const projectDetailById = (id) => async (dispatch) => {
  dispatch({
    type: PROJECT_DETAILS_REQUEST,
    payload: id,
  });
  try {
    const { data } = await axios.get(`${BASE_URL}/project/${id}`);

    dispatch({
      type: PROJECT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROJECT_DETAILS_FAIL,
      payload: error,
    });
  }
};

export const projecRelated = (id) => async (dispatch) => {
  dispatch({
    type: PROJECT_RELATED_REQUEST,
    payload: id,
  });
  try {
    const { data } = await axios.get(`${BASE_URL}/relatedProjects/${id}`);

    dispatch({
      type: PROJECT_RELATED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROJECT_RELATED_FAIL,
      payload: error,
    });
  }
};
