import React from "react";
import { FaTelegram } from "react-icons/fa";

const BackToTopButton = ({ showButton }) => {
  return (
    <div className="fixed bottom-0 right-0 grid gap-2 mb-4 mr-4 z-30">
      <a
        href="https://t.me/simabtrading"
        rel="noopener noreferrer"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex items-center justify-center w-9 h-9"
      >
        <button
          className={`bg-blue-600 shadow-primary/60 text-white rounded-full shadow back-to-top-btn w-9 h-9 place-items-center ${
            showButton && "active"
          }`}
          onClick={() => window.scrollTo(0, 0)}
        >
          <FaTelegram size={35} />
        </button>
      </a>
    </div>
  );
};

export default BackToTopButton;
