import {} from "../constants/CourseConstants";
import {
  NEWS_DETAILS_FAIL,
  NEWS_DETAILS_REQUEST,
  NEWS_DETAILS_SUCCESS,
  NEWS_LIST_FAIL,
  NEWS_LIST_REQUEST,
  NEWS_LIST_SUCCESS,
  NEWS_RELATED_FAIL,
  NEWS_RELATED_REQUEST,
  NEWS_RELATED_SUCCESS,
} from "../constants/newsConstants";

export const newsListReducer = (
  state = { loading: true, news: [] },
  action
) => {
  switch (action.type) {
    case NEWS_LIST_REQUEST:
      return {
        loading: true,
      };
    case NEWS_LIST_SUCCESS:
      return {
        loading: false,
        news: action.payload,
      };
    case NEWS_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const newsDetailListReducer = (
  state = { loading: true, news: [] },
  action
) => {
  switch (action.type) {
    case NEWS_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case NEWS_DETAILS_SUCCESS:
      return {
        loading: false,
        news: action.payload,
      };
    case NEWS_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const newsRelatedListReducer = (
  state = { loading: true, Relatednews: [] },
  action
) => {
  switch (action.type) {
    case NEWS_RELATED_REQUEST:
      return {
        loading: true,
      };
    case NEWS_RELATED_SUCCESS:
      return {
        loading: false,
        Relatednews: action.payload,
      };
    case NEWS_RELATED_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
