import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { FaQuoteRight } from "react-icons/fa";
// import { ReactStars } from "react-rating-stars-component";
import TestimonialCard from "./TestimonialCard";
import { testimonialList } from "../../../action/testimonialAction";

const Testimonial = () => {
  const dispatch = useDispatch();
  const { loading, error, testimonial } = useSelector(
    (state) => state.TestimonialList
  );

  useEffect(() => {
    dispatch(testimonialList());
  }, [dispatch]);

  return (
    <section className="pt-10 pb-16">
      <div className="text-center">
        <h2 className="section-title text-4xl font-extrabold text-primary">
          Testimonials
        </h2>
        <h1 className="heading mt-2">What They're Saying About Our Work</h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-8">
        {testimonial?.map((item) => (
          <div key={item.id} className="bg-white shadow-lg rounded-lg p-6">
            <div className="flex items-center mb-4">
              <div className="w-12 h-12 flex items-center justify-center bg-blue-100 text-white rounded-full">
                <FaQuoteRight className="text-2xl" />
              </div>
              <p className="ml-4 text-lg">{item.description}</p>
            </div>
            <div className="flex items-center">
              <img
                src={item.photo}
                alt={item.name}
                className="w-10 h-10 rounded-full mr-4"
              />
              <div>
                <h1 className="font-semibold text-xl capitalize">
                  {item.name}
                </h1>
                <p className="text-sm capitalize">{item.title}</p>
                <div className="mt-2">
                  {/* <ReactStars
                    count={5}
                    size={24}
                    activeColor="#ffd700"
                    value={item.rating} // Assuming each testimonial has a 'rating' property
                    edit={false}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonial;
