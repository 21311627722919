import { dataStore } from "../../../features/dataSlice";
import SingleFeedCard from "./SingleFeedCard";

import { feeds } from "../../../data/dummyData";
import SingleFeedCardGrid from "../../common/page-componets/SingleFeedCardGrid";
import { useEffect } from "react";
import { createNews, newsList } from "../../../action/newsAction";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";

const BlogPostsList = () => {
  const { currentDataItems } = useSelector(dataStore);
  // const { currentDataItems } = useSelector(dataStore);
  const dispatch = useDispatch();
  const { loading, error, news } = useSelector((state) => state.NewsList);

  useEffect(() => {
    dispatch(newsList());
  }, [dispatch]);
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {news?.map((item) => (
        <div
          key={item.id}
          className="bg-white shadow-light dark:bg-gray-800 dark:border-card-dark border rounded-lg overflow-hidden relative group"
        >
          <div className="overflow-hidden">
            <Link to={`/event_details/${item.id}`}>
              <img
                src={item?.image}
                alt={item.title}
                className="w-full h-[150px] sm:h-[150px] md:h-[200px] lg:h-[250px] object-cover group-hover:scale-105 transition-transform duration-300"
              />
            </Link>
          </div>
          <div className="absolute top-2 left-2">
            <span className="px-3 py-1 text-white capitalize rounded-full bg-secondary">
              {item.category}
            </span>
          </div>
          <div className="p-3">
            <p className="text-sm uppercase opacity-60">
              by {item.created_by["username"]}: {item.created_date}
            </p>
            <Link
              to={`/event_details/${item.id}`}
              className="text-lg font-semibold capitalize text-primary hover:underline"
            >
              {item.title}
            </Link>
            <Link
              to={`/event_details/${item.id}`}
              className="mt-4 flex items-center gap-x-2 text-primary hover:underline"
            >
              <span className="uppercase">read more</span> <FiArrowRight />
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogPostsList;
