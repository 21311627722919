import { Helmet } from "react-helmet";
import { BlogPostsList, Pagination } from "../components/common/page-componets";
import { feeds } from "../data/dummyData";

const Blog = () => {
  return (
    <>
      {" "}
      <Helmet>
        <meta
          charSet="utf-8"
          name="SimbaTech pages"
          content="simbatech Events page"
        />
        <title>SimbaTech | Events</title>
        <link rel="canonical" href="/events" />
      </Helmet>
      <div className="pt-20 px-[3%] md:px-[6%]">
        <BlogPostsList />
        <Pagination itemsPerPage={6} pageData={feeds} />
      </div>
    </>
  );
};

export default Blog;
