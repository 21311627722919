import { Helmet } from "react-helmet";
import {
  AboutUs,
  Brands,
  Feeds,
  Team,
  Testimonial,
} from "../components/common/page-componets";

const About = () => {
  return (
    <>
      <Helmet>
        <meta
          charSet="utf-8"
          name="SimbaTech pages"
          content="simbatech about page"
        />
        <title>SimbaTech | About</title>
        <link rel="canonical" href="/about" />
      </Helmet>
      <div className="pt-20 px-[3%] md:px-[6%]">
        <AboutUs />
        <Team />
      </div>
    </>
  );
};

export default About;
